<template>
    <div class="uc-header">
        <div class="header-center">
            <a href="https://www.sunofbeach.net">
                <div id="uc-logo">
                    SOB
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #uc-logo {
        color: #ffffff;
        font-size: 34px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 5px;
    }

    #uc-logo:hover {
        opacity: .3;
    }

    .header-center {
        position: relative;
        width: 1140px;
        margin: 0 auto;
    }

    .uc-header {
        height: 47px;
        background: #0084ff;
    }
</style>
