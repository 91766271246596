<template>
    <div id="login">
        <div class="title">登入</div>
        <div class="login-info">
            <div class="input-item">
                <el-input placeholder="请输入手机号码" v-model="user.phoneNum" maxlength="11"
                          oninput="value=value.replace(/[^\d]/g,'')">
                    <template slot="prepend">手机号</template>
                </el-input>
            </div>
            <div class="input-item">
                <el-input placeholder="请输入密码" v-model="pwd" type="password">
                    <template slot="prepend">密码</template>
                </el-input>
            </div>
            <div class="input-item">
                <el-input placeholder="请输入人类验证码" v-model="captcha">
                    <template slot="prepend">图灵码</template>
                </el-input>
                <img @click="updateCaptcha" class="captcha" :src="captchaSrc">
            </div>
            <div class="submit-btn">
                <el-button type="primary" @click="doLogin">登录</el-button>
            </div>
            <div style="margin-top: 10px;margin-left: 10px;">
                <router-link to="/forget" style="margin-right: 10px;">忘记密码</router-link>
                <router-link to="/register">注册新账号</router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import * as md5 from '../../utils/md5';
    import * as api from '../../api/api';
    import cache from '../../utils/cache';

    export default {
        name: 'login',
        data() {
            return {
                captchaSrc: api.BASE_URL + '/uc/ut/captcha',
                pwd: '',
                captcha: '',
                user: {
                    phoneNum: '',
                    password: ''
                },
                redirectUrl: ''
            }
        },
        methods: {
            updateCaptcha() {
                this.captchaSrc = this.captchaSrc + "?" + Math.random();
            },
            doLogin() {
                //先检查一下数据
                if (this.user.phoneNum === '') {
                    this.$message.error('手机号码不可以为空');
                    return;
                }
                let reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
                if ((!reg.test(this.user.phoneNum))) {
                    this.$message.error('手机号码格式不对');
                    return;
                }
                if (this.pwd === '') {
                    this.$message.error('密码不可以为空');
                    return;
                }
                if (this.captcha === '') {
                    this.$message.error('人类验证码不可以为空');
                    return;
                }
                //然后加密
                //console.log(md5.hex_md5(this.pwd));
                this.user.password = md5.hex_md5(this.pwd);
                //登录
                api.doLogin(this.captcha, this.user).then(result => {
                    //处理结果
                    //console.log(result);
                    if (result.code === 11111) {
                        this.$message.error(result.message);
                        this.updateCaptcha();
                    } else if (result.code === 10000) {
                        //console.log(this.redirectUrl);
                        //登录成功了, 如果有redirect就跳转到对应的位置，否则跳转到首页
                        if (this.redirectUrl !== '') {
                            //跳转到对应的页面
                            if (/(%[\da-z]{2})+/i.test(this.redirectUrl)) {
                                this.redirectUrl = decodeURIComponent(this.redirectUrl);
                            }
                            window.location.href = this.redirectUrl;
                        } else {
                            //跳转到首页
                            window.location.href = "https://www.sunofbeach.net"
                        }
                    } else if (result.code === 40003) {
                        this.$message.error(result.message);
                        //跳转到首页
                        window.location.href = "https://www.sunofbeach.net";
                    } else {
                        this.$message.error(result.message);
                    }
                })
            },
        },
        destroyed() {
            document.onkeydown = null;
        },
        mounted() {
            let vm = this;
            document.onkeydown = function (e) {
                let key = window.event.keyCode;
                if (key === 13) {
                    vm.doLogin();
                }
            };
            let url = document.location.toString();
            if (url.search("redirect=") !== -1) {
                //console.log(url);
                let index = url.indexOf("redirect=");
                //console.log(index);
                let result = url.substr(index);
                result = result.replace("redirect=", "");
                // console.log(result);
                this.redirectUrl = result;
            }
        }
    }
</script>
<style>

    .title {
        font-size: 20px;
        padding: 10px;
        color: #343436;
    }

    .el-input__inner {
        border: 1px solid #e6e6e6;
        height: 42px;
        line-height: 42px;
    }

    .el-input-group__append, .el-input-group__prepend {
        color: #000000;
        width: 40px;
        background: #FBFBFB;
        text-align: center;
    }

    .input-item {
        padding: 8px;
    }

    .submit-btn a {
        margin-left: 20px;
    }

    .submit-btn .el-button--primary {
        width: 100px;
    }

    .submit-btn {
        padding: 8px;
    }

    .el-input-group--prepend {
        width: 280px;
    }

    .captcha {
        margin-left: 20px;
        cursor: pointer;
        border-radius: 4px;
        vertical-align: middle;
    }

    #login {
        background: #fff;
        border-radius: 4px;
        padding: 20px;
    }
</style>
