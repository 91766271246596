<template>
    <div class="uc-footer">
        <div id="uc-footer-center">
            <div>
                Copyright © 2014-<span v-text="currentYear"></span>
                <a href="https://www.sunofbeach.net"> 阳光沙滩</a> code by
                <a href="https://www.sunofbeach.net/u/1153952789488054272" target="_blank">TrillGates</a>
            </div>
            <div id="bottom-links">
                <a href="https://www.sunofbeach.net/about">关于我们</a>
                <span>|</span>
                <a href="https://www.sunofbeach.net/jobs">加入我们</a>
                <span>|</span>
                <a href="https://www.sunofbeach.net/links">友情链接</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                currentYear: ''
            }
        },
        mounted() {
            this.currentYear = new Date().getFullYear();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    #bottom-links {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #bottom-links span {
        margin-left: 10px;
        margin-right: 10px;
    }

    .uc-footer {
        padding-top: 30px;
        padding-bottom: 50px;
        margin-top: 50px;
        margin-bottom: 50px;
        border-top: 1px solid #e2e2e2;
    }

    #uc-footer-center {
        color: #737573;
        display: block;
        text-align: center;
        margin: 0 auto;
    }
</style>
