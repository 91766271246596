<template>
    <div id="register">
        <div class="title">注册</div>
        <div class="register-info">
            <div class="input-item">
                <el-input placeholder="请输入人类验证码" v-model="captcha">
                    <template slot="prepend">图灵码</template>
                </el-input>
                <img @click="updateCaptcha" class="captcha" :src="captchaSrc">
            </div>
            <div class="input-item">
                <el-input placeholder="请输入手机号码" v-model="user.phoneNum" maxlength="11"
                          oninput="value=value.replace(/[^\d]/g,'')">
                    <template slot="prepend">手机号</template>
                </el-input>
                <el-button v-if="!isCountDowning" class="register-btn" type="primary" @click="getVerifyCode"> 获取验证码
                </el-button>
                <el-button v-else class="register-btn" type="primary" disabled> {{countDownText}}
                </el-button>
            </div>
            <div class="input-item">
                <el-input placeholder="请输入手机验证码" v-model="smsCode">
                    <template slot="prepend">验证码</template>
                </el-input>
            </div>
            <div class="input-item">
                <el-input placeholder="独一无二的花名" v-model="user.nickname">
                    <template slot="prepend">昵称</template>
                </el-input>
            </div>
            <div class="input-item">
                <el-input placeholder="世间唯一的密码" v-model="pwd" type="password">
                    <template slot="prepend">密码</template>
                </el-input>
            </div>
            <div class="submit-btn">
                <el-button type="primary" @click="doRegister">注册</el-button>
                <router-link to="/login" style="margin-left: 14px;">我已注册，登录？</router-link>
            </div>
        </div>
    </div>
</template>
<script>
    import {getPhoneVerifyCode, doRegister, BASE_URL} from '../../api/api';
    import {hex_md5} from '../../utils/md5';
    import cache from '../../utils/cache';

    export default {
        name: 'register',
        data() {
            return {
                captchaSrc: BASE_URL + '/uc/ut/captcha',
                captcha: '',
                pwd: '',
                user: {
                    phoneNum: '',
                    password: '',
                    nickname: ''
                },
                smsCode: '',
                countDownText: '重新发送(60)',
                isCountDowning: false,
                sendSmsVo: {
                    phoneNumber: '',
                    verifyCode: ''
                },
            }
        },
        methods: {
            getVerifyCode() {
                //先检查
                if (this.captcha === '') {
                    this.$message.error('人类验证码不可以为空');
                    return;
                }
                if (this.user.phoneNum === '') {
                    this.$message.error('手机号码不可以为空哦（^_^）');
                    return;
                }
                let reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
                if ((!reg.test(this.user.phoneNum))) {
                    this.$message.error('手机号码格式不对哇（^_^）');
                    return;
                }
                this.sendSmsVo.phoneNumber = this.user.phoneNum;
                this.sendSmsVo.verifyCode = this.captcha;
                //再调用
                getPhoneVerifyCode(this.sendSmsVo).then(result => {
                    if (result.code === 11114) {
                        //发送成功，开始倒计时
                        this.startCountDown();
                        this.$message.success(result.message);
                    } else {
                        this.updateCaptcha();
                        this.$message.error(result.message);
                    }
                });
            },
            doRegister() {
                //检查内容
                if (this.captcha === '') {
                    this.$message.error('人类验证码不可以为空');
                    return;
                }
                if (this.user.phoneNum === '') {
                    this.$message.error('手机号码不可以为空哦（^_^）');
                    return;
                }
                let reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
                if ((!reg.test(this.user.phoneNum))) {
                    this.$message.error('手机号码格式不对哇（^_^）');
                    return;
                }
                if (this.smsCode === '') {
                    this.$message.error('验证码没有填写呢!');
                    return;
                }
                if (this.user.nickname === '') {
                    this.$message.error('你还没有写一个很酷的名字哦!');
                    return;
                }
                if (this.pwd === '') {
                    this.$message.error('怎么可以没有密码呢?');
                    return;
                }
                this.user.password = hex_md5(this.pwd);
                doRegister(this.user, this.smsCode).then(result => {
                    if (result.code === 11117) {
                        //注册成功，跳转到登录界面
                        this.$router.push({
                            path: '/login'
                        });
                        this.$message.success(result.message);
                    } else {
                        //注册失败，重新获取验证码
                        this.updateCaptcha();
                        this.$message.error(result.message);
                    }
                })
            },
            startCountDown() {
                let time = 60;
                this.isCountDowning = true;
                let that = this;
                let interval = setInterval(function () {
                    //执行倒计时内容
                    time--;
                    if (time <= 0) {
                        that.isCountDowning = false;
                        clearInterval(interval);
                    }
                    that.countDownText = '重新发送(' + time + ')';
                    // console.log('倒计时 == >  ' + time + '  ' + that.countDownText);
                }, 1000);
            },
            updateCaptcha() {
                this.captchaSrc = this.captchaSrc + "?" + Math.random();
            }
        }
    }
</script>
<style>

    .register-btn {
        margin-left: 20px;
    }

    .el-input-group--prepend {
        width: 280px;
    }


    .title {
        font-size: 20px;
        padding: 10px;
        color: #343436;
    }

    .input-item {
        padding: 8px;
    }


    .captcha {
        margin-left: 20px;
        cursor: pointer;
        border-radius: 4px;
        vertical-align: middle;
    }

    .el-input__inner {
        border: 1px solid #e6e6e6;
        height: 42px;
        line-height: 42px;
    }

    .el-input-group__append, .el-input-group__prepend {
        color: #000000;
        width: 40px;
        background: #FBFBFB;
        text-align: center;
    }

    #register {
        background: #fff;
        border-radius: 4px;
        padding: 20px;
    }

    .submit-btn .el-button--primary {
        width: 100px;
    }

    .submit-btn {
        padding: 8px;
    }
</style>
