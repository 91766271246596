<template>
    <div id="reset-pwd">
        <div class="title">重置密码</div>
        <div class="reset-part">
            <div class="input-item">
                <el-input placeholder="请输入原密码" v-model="oPwd" type="password">
                    <template slot="prepend">原密码</template>
                </el-input>
            </div>
            <div class="input-item">
                <el-input placeholder="请输入新密码" v-model="nPwd" type="password">
                    <template slot="prepend">新密码</template>
                </el-input>
            </div>
            <div class="input-item">
                <el-input placeholder="请输入人类验证码" v-model="modifyPwdVo.captcha">
                    <template slot="prepend">图灵码</template>
                </el-input>
                <img @click="updateCaptcha" class="captcha" :src="captchaSrc">
            </div>
            <div class="submit-btn">
                <el-button type="primary" @click="modifyPwd">修改密码</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {modifyPwd, logout, BASE_URL} from '../../api/api'
    import {hex_md5} from '../../utils/md5';

    export default {
        name: 'resetPwd',
        data() {
            return {
                oPwd: '',
                nPwd: '',
                captchaSrc: BASE_URL + '/uc/ut/captcha',
                modifyPwdVo: {
                    oldPwd: '',
                    newPwd: '',
                    captcha: ''
                }
            }
        },
        methods: {
            updateCaptcha() {
                this.captchaSrc = this.captchaSrc + "?" + Math.random();
            },
            modifyPwd() {
                //检查内容
                if (this.oPwd === '') {
                    this.$message.error('原密码不可以为空.');
                    return;
                }
                if (this.nPwd === '') {
                    this.$message.error('新密码不可以为空.');
                    return;
                }
                if (this.modifyPwdVo.captcha === '') {
                    this.$message.error('请输入人类验证码.');
                    return;
                }
                //对密码进行加密
                this.modifyPwdVo.oldPwd = hex_md5(this.oPwd);
                this.modifyPwdVo.newPwd = hex_md5(this.nPwd);
                modifyPwd(this.modifyPwdVo).then(result => {
                    this.updateCaptcha();
                    if (result.code === 10000) {
                        //调用退出登录
                        logout();
                        this.$message.success(result.message);
                        this.$router.push({
                            path: '/login'
                        })
                    } else {
                        this.$message.error(result.message);
                    }
                });
            }
        }
    }
</script>
<style>

    .el-input__inner {
        border: 1px solid #e6e6e6;
        height: 42px;
        line-height: 42px;
    }

    .el-input-group__append, .el-input-group__prepend {
        color: #000000;
        width: 40px;
        background: #FBFBFB;
        text-align: center;
    }

    .input-item {
        padding: 8px;
    }

    .submit-btn a {
        margin-left: 20px;
    }

    .submit-btn .el-button--primary {
        width: 100px;
    }

    .submit-btn {
        padding: 8px;
    }

    .el-input-group--prepend {
        width: 280px;
    }

    .captcha {
        margin-left: 20px;
        cursor: pointer;
        border-radius: 4px;
        vertical-align: middle;
    }

    .title {
        font-size: 20px;
        padding: 10px;
        color: #343436;
    }


    #reset-pwd {
        background: #fff;
        border-radius: 4px;
        padding: 20px;
    }
</style>
