<template>
    <div id="home">
        <div class="function-list">
            <div class="uc-login-state" v-if="isLogin">
                <div class="user-info">
                    <img :src="avatar">
                    <div class="nick-name" v-text="nickName"></div>
                </div>
                <!--登录显示-->
                <el-button type="danger" @click="doLogout">退出登录</el-button>
                <router-link class="el-button--primary el-button" to="/reset-pwd">重置密码</router-link>
            </div>
            <div class="uc-logout-state clear-fix" v-else>
                <!--未登录显示-->
                <div class="login-tips">
                    <div class="tips-text">登录以后您可以：<br/>
                        <ul>
                            <li>发动态</li>
                            <li>发表文章</li>
                            <li>下载课程资料</li>
                            <li>提问题或解答问题</li>
                            <li>使用阳光沙滩提供的API</li>
                            <li>分享您的公众号、博客文章</li>
                        </ul>
                    </div>
                    <router-link class="el-button--primary el-button" to="/login">登录</router-link>
                </div>
                <div class="register-tips">
                    <div class="tips-text">成为阳光沙滩用户：<br/>
                        <ul>
                            <li>您才可以登录</li>
                            <li>升级为VIP会员</li>
                            <li>享有登录后的功能</li>
                            <li>赶紧注册加入我们吧</li>
                            <li>赚取积分，上富豪排行榜</li>
                            <li>快叫上您的朋友(们)、同学(们)</li>
                        </ul>
                    </div>
                    <router-link class="el-button--primary el-button" to="/register">注册</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import cache from '../../utils/cache';
    import {logout} from '../../api/api'

    export default {
        name: 'home',
        data() {
            return {
                isLogin: false,
                avatar: '',
                nickName: ''
            }
        },
        methods: {
            doLogout() {
                //console.log("logout..");
                cache.logout();
                logout().then(result => {
                    //跳转到登录界面
                    this.$router.push({
                        path: '/login'
                    })
                });
                document.cookie = "sob_token="
                    + "; expires=-1";
                document.cookie = "sob_token="
                    + "; expires=-1"
                    + "; domain=.sunofbeach.net";
            }
        },
        mounted() {
            this.nickName = cache.getNickname();
            this.avatar = cache.getAvatar();
            this.isLogin = cache.isLogin();
        }
    }
</script>
<style>

    .user-info:hover {
        opacity: .4;
    }

    .user-info {
        cursor: pointer;
        display: inline;
    }

    .user-info .nick-name {
        font-size: 20px;
        color: #6f6f6f;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .user-info img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
    }

    .tips-text ul {
        margin-top: 20px;
        margin-left: 40px;
    }

    .tips-text {
        line-height: 24px;
        text-align: left;
        margin-bottom: 20px;
    }

    .login-tips .el-button--primary, .register-tips .el-button--primary {
        width: 200px;
        display: inline-block;
    }

    .login-tips, .register-tips {
        width: 400px;
        background: #fff;
        padding: 20px;
        border-radius: 4px;
    }

    .register-tips {
        margin-right: 70px;
        float: right;
    }

    .login-tips {
        margin-left: 70px;
        float: left;
    }

    .uc-logout-state {
        text-align: center;
    }

    .function-list {
        text-align: center;
    }
</style>
