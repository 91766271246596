<template>
    <div id="forget">
        <div class="title">找回密码</div>
        <div class="verify-code-part">
            <div class="input-item">
                <el-input placeholder="请输入人类验证码" :disabled="isSmsCodeRight" v-model="sendSmsVo.verifyCode">
                    <template slot="prepend">图灵码</template>
                </el-input>
                <img @click="updateCaptcha" class="captcha" :src="captchaSrc">
            </div>
            <div class="input-item">
                <el-input placeholder="请输入手机号码" :disabled="isSmsCodeRight" v-model="sendSmsVo.phoneNumber"
                          maxlength="11"
                          oninput="value=value.replace(/[^\d]/g,'')">
                    <template slot="prepend">手机号</template>
                </el-input>
                <el-button v-if="!isCountDowning" class="get-verify-code-btn" type="primary"
                           @click="getVerifyCode" :disabled="isSmsCodeRight">获取验证码
                </el-button>
                <el-button v-else class="get-verify-code-btn" type="primary" disabled> {{countDownText}}
                </el-button>
            </div>
            <div class="input-item">
                <el-input placeholder="请输入手机验证码" :disabled="isSmsCodeRight" v-model="smsCode">
                    <template slot="prepend">验证码</template>
                </el-input>
            </div>
            <div class="input-item" v-if="isSmsCodeRight">
                <el-input placeholder="请输入新密码" v-model="newPwd" type="password">
                    <template slot="prepend">新密码</template>
                </el-input>
            </div>
            <div class="submit-btn" v-show="!isSmsCodeRight">
                <el-button type="primary" @click="doCheck">提交</el-button>
            </div>
            <div class="submit-btn" v-show="isSmsCodeRight">
                <el-button type="primary" @click="modifyPwd">修改密码</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {getForgetVerifyCode, checkSmsCode, updatePassword, BASE_URL} from '../../api/api'
    import {hex_md5} from '../../utils/md5';
    import cache from '../../utils/cache';

    export default {
        name: 'forget',
        data() {
            return {
                isSmsCodeRight: false,
                newPwd: '',
                captchaSrc: BASE_URL + '/uc/ut/captcha',
                sendSmsVo: {
                    phoneNumber: '',
                    verifyCode: ''
                },
                smsCode: '',
                isCountDowning: false,
                countDownText: '重新发送(60)',
                userVo: {
                    phoneNum: '',
                    password: ''
                }
            }
        },
        methods: {
            getVerifyCode() {
                //先检查
                if (this.sendSmsVo.verifyCode === '') {
                    this.$message.error('人类验证码不可以为空');
                    return;
                }
                if (this.sendSmsVo.phoneNumber === '') {
                    this.$message.error('手机号码不可以为空哦（^_^）');
                    return;
                }
                let reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
                if ((!reg.test(this.sendSmsVo.phoneNumber))) {
                    this.$message.error('手机号码格式不对哇（^_^）');
                    return;
                }

                //再调用
                getForgetVerifyCode(this.sendSmsVo).then(result => {
                    if (result.code === 11114) {
                        //发送成功，开始倒计时
                        this.startCountDown();
                        this.$message.success(result.message);
                    } else {
                        this.updateCaptcha();
                        this.$message.error(result.message);
                    }
                });
            },
            modifyPwd() {
                //检查数据
                if (this.sendSmsVo.phoneNumber === '') {
                    this.$message.error('手机号码不可以为空哦（^_^）');
                    return;
                }
                let reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
                if ((!reg.test(this.sendSmsVo.phoneNumber))) {
                    this.$message.error('手机号码格式不对哇（^_^）');
                    return;
                }
                this.userVo.phoneNum = this.sendSmsVo.phoneNumber;
                if (this.smsCode === '') {
                    this.$message.error('手机验证码不可以为空.');
                    return;
                }
                //密码判断和处理
                if (this.newPwd === '') {
                    this.$message.error('密码不可以为空.');
                    return;
                }
                //密码加密
                this.userVo.password = hex_md5(this.newPwd);
                updatePassword(this.userVo, this.smsCode).then(result => {
                    if (result.code === 10000) {
                        this.$message.success(result.message);
                        //跳转到登录界面
                        this.$router.push({
                            path: '/login'
                        })
                    } else {
                        this.$message.error(result.message);
                    }
                });
            },
            doCheck() {
                //检查验证码是否正确，如查正确则显示修改密码项
                checkSmsCode(this.sendSmsVo.phoneNumber, this.smsCode).then(result => {
                    if (result.code === 10000) {
                        //显示修改密码项
                        this.isSmsCodeRight = true;
                    } else {
                        this.$message.error(result.message);
                    }
                })
            },
            startCountDown() {
                let time = 60;
                this.isCountDowning = true;
                let that = this;
                let interval = setInterval(function () {
                    //执行倒计时内容
                    time--;
                    if (time <= 0) {
                        that.isCountDowning = false;
                        clearInterval(interval);
                    }
                    that.countDownText = '重新发送(' + time + ')';
                    // console.log('倒计时 == >  ' + time + '  ' + that.countDownText);
                }, 1000);
            },
            updateCaptcha() {
                this.captchaSrc = this.captchaSrc + "?" + Math.random();
            }
        }
    }
</script>
<style>


    .el-input__inner {
        border: 1px solid #e6e6e6;
        height: 42px;
        line-height: 42px;
    }

    .el-input-group__append, .el-input-group__prepend {
        color: #000000;
        width: 40px;
        background: #FBFBFB;
        text-align: center;
    }

    .input-item {
        padding: 8px;
    }

    .submit-btn a {
        margin-left: 20px;
    }

    .submit-btn .el-button--primary {
        width: 100px;
    }

    .submit-btn {
        padding: 8px;
    }

    .get-verify-code-btn {
        margin-left: 22px;
    }

    .el-input-group--prepend {
        width: 280px;
    }

    .captcha {
        margin-left: 20px;
        cursor: pointer;
        border-radius: 4px;
        vertical-align: middle;
    }

    .title {
        font-size: 20px;
        padding: 10px;
        color: #343436;
    }

    #forget {
        background: #fff;
        border-radius: 4px;
        padding: 20px;
    }
</style>
