import axios from 'axios'
import cache from '../utils/cache';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 1000000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www=form-urlencoded';

// 添加请求拦截器
axios.interceptors.request.use(function (request) {
    // 在发送请求之前做些什么
    let captchaKey = cache.getLocal("captchaKey");
    request.headers.l_c_i = captchaKey;
    console.log("captchaKey ==> " + captchaKey);
    let sobToken = cache.getLocal("sob_token");
    request.headers.sob_token = sobToken;
    return request;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    //把token保存起来
    let sobToken = response.headers.sob_token;
    if (sobToken) {
        let expireDays = 30;
        let exDate = new Date();
        exDate.setDate(exDate.getDate() + expireDays);
        document.cookie = "sob_token=" + sobToken
            + "; expires=" + exDate.toGMTString();
        cache.setLocal("sob_token", sobToken);
    }
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default {
    // get请求
    requestGet(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, params).then(res => {
                console.log(res);
                resolve(res.data)
            }).catch(error => {
                reject(error)
            });
        })
    },
    // post请求
    requestPost(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, params).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // delete请求
    requestDelete(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.delete(url, params).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // put请求
    requestPut(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.put(url, params).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    }

}
