<template>
    <div id="app">
        <Header></Header>
        <!-- 视图 -->
        <div id="uc-center">
            <transition name="el-fade-in-linear" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
        <Footer></Footer>
        <!-- 进度条 -->
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<script>
    import Header from './components/Header.vue'
    import Footer from './components/Footer.vue'

    export default {
        name: 'App',
        components: {
            Header,
            Footer
        },
        methods: {
            init_Progress() {
                this.$router.beforeEach((to, from, next) => {
                    this.$Progress.start();
                    next()
                });
                this.$router.afterEach(() => {
                    this.$Progress.finish()
                })
            }
        },
        created() {
            this.$Progress.start();
            this.init_Progress()
        },
        mounted() {
            this.$Progress.finish();
        }
    }
</script>

<style>

    .clear-fix:before,
    .clear-fix:after {
        content: "";
        display: table; /* 也可以设置为block */
    }

    .clear-fix:after {
        clear: both;
    }

    .clear-fix {
        zoom: 1;
    }

    .el-message {
        min-width: 0px !important;
    }

    #uc-center {
        width: 1140px;
        margin: 20px auto;
        padding: 20px;
    }

    * {
        padding: 0;
        margin: 0;
    }

    html {
        background-color: #F2F2F2;
    }

    a:hover {
        opacity: .7;
    }

    a {
        text-decoration: none;
        color: #737573;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
