import http from './http';


export const success_code = 10000;
export const failed_code = 40000;
// export const BASE_URL = "http://localhost:51000";
export const BASE_URL = "https://api.sunofbeach.net";

//解析token
export const checkToken = () => {
    return http.requestGet(BASE_URL + '/uc/user/checkToken');
};

//登录
export const doLogin = (captcha, user) => {
    return http.requestPost(BASE_URL + '/uc/user/login/' + captcha + "?from=p_", user);
};
//登出
export const logout = () => {
    return http.requestGet(BASE_URL + '/uc/user/logout');
};
//获取手机验证码
export const getPhoneVerifyCode = (sendSmsVo) => {
    return http.requestPost(BASE_URL + '/uc/ut/join/send-sms', sendSmsVo);
};
//获取找回密码的验证码
export const getForgetVerifyCode = (sendSmsVo) => {
    return http.requestPost(BASE_URL + '/uc/ut/forget/send-sms', sendSmsVo);
};
//注册账号
export const doRegister = (user, smsCode) => {
    return http.requestPost(BASE_URL + '/uc/user/register/' + smsCode, user);
};
//检查验证码是否真确
export const checkSmsCode = (phoneNumber, smsCode) => {
    return http.requestGet(BASE_URL + '/uc/ut/check-sms-code/' + phoneNumber + '/' + smsCode);
};
//找回密码
export const updatePassword = (userVo, smsCode) => {
    return http.requestPut(BASE_URL + '/uc/user/forget/' + smsCode, userVo);
};
//修改密码
export const modifyPwd = (userVo) => {
    return http.requestPut(BASE_URL + '/uc/user/modify-pwd', userVo);
};




