import Vue from 'vue'
import App from './App.vue'
import router from './router'
import cache from './utils/cache';

import VueProgressBar from 'vue-progressbar'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


Vue.config.productionTip = false;


Vue.use(VueProgressBar, {
    failedColor: 'red',
    thickness: '4px'
});
Vue.use(ElementUI);

import {checkToken, success_code} from './api/api';

router.beforeEach((to, from, next) => {
    //如果没有token，则清楚存的东西
    let needCheck = false;
    if (!cache.getLocal('sob_token')) {
        cache.logout();
    } else {
        //有token，判断存储里是否有内容
        if (!cache.isLogin()) {
            needCheck = true;
        }
        let sobToken = cache.getLocal("sob_token");
        console.log("sobToken ===> " + sobToken);
        if (sobToken) {
            let expireDays = 30;
            let exDate = new Date();
            exDate.setDate(exDate.getDate() + expireDays);
            document.cookie = "sob_token=" + sobToken
                + "; expires=" + exDate.toGMTString();
        }
    }
    if (needCheck || to.name === 'login' || to.name === 'register' || to.name === 'forget') {
        checkToken().then(result => {
            //console.log(result);
            //如果已经登录了，则跳转到首页
            if (result.code === success_code) {
                //把数据保存一下，跳转到首页
                let data = result.data;
                cache.saveAvatar(data.avatar);
                cache.saveNickname(data.nickname);
                cache.saveIsLogin(true);
                router.push({
                    path: '/index'
                })
            } else {
                next();
            }
        });
    } else {
        next();
    }
});
/* eslint-disable no-new */
new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
