import Vue from 'vue'
import VueRouter from 'vue-router'

// SOB页面start
const forget = () => import('@/page/forget/index');
const login = () => import('@/page/login/index');
const register = () => import('@/page/register/index');
const index = () => import('@/page/home/index');
const resetPwd = () => import('@/page/resetpwd/index');

Vue.use(VueRouter);
export const routes = [
    {
        path: '',
        redirect: '/index'
    },
    {
        name: 'index',
        path: '/index',
        component: index
    },
    {
        name: 'login',
        path: '/login',
        component: login
    }, {
        name: 'register',
        path: '/register',
        component: register
    }, {
        name: 'forget',
        path: '/forget',
        component: forget
    }, {
        name: 'resetPwd',
        path: '/reset-pwd',
        component: resetPwd
    }
];

const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

export default router;
