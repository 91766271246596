export default {

    saveFansCount: function (fansCount) {
        window.localStorage.setItem('fansCount', fansCount)
    },
    getFansCount: function () {
        return window.localStorage.getItem('fansCount')
    },
    saveNickname: function (nickname) {
        window.localStorage.setItem('nickname', nickname)
    },
    saveIsLogin: function (isLogin) {
        window.localStorage.setItem('isLogin', isLogin)
    },
    isLogin: function () {
        return window.localStorage.getItem('isLogin')
    },
    getNickname: function () {
        return window.localStorage.getItem('nickname')
    },
    saveAvatar: function (path) {
        window.localStorage.setItem('avatar', path)
    },
    getAvatar: function () {
        return window.localStorage.getItem('avatar')
    },
    getLocal: function (name) {
        if (!name) return;
        return window.localStorage.getItem(name)
    },
    setLocal: function (name, content) {
        if (!name) return;
        if (typeof content !== 'string') {
            content = JSON.stringify(content)
        }
        window.localStorage.setItem(name, content)
    },
    removeLocal: function (name) {
        if (!name) return;
        window.localStorage.removeItem(name)
    },
    logout: function () {
        window.localStorage.clear();
    }
}
